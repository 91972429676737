import { useCurrentUser } from '@qualio/ui-components';
import qualioApi from 'api/qualio.api';
import { useQuery } from 'react-query';
import { IncomingCompany, IncomingUser } from 'types';

type UseCurrentUserQueryResult = {
  user: IncomingUser;
  isUserLoading: boolean;
  userError: any;
  userGroups: IncomingCompany['usergroups'];
  permissions: string[];
  isValidSuppliersPlan: boolean;
};

export const useCurrentUserQuery = (): UseCurrentUserQueryResult => {
  const { userId, companyId, capabilities } = useCurrentUser();
  const {
    data: user,
    isLoading: isUserLoading,
    error: userError,
  } = useQuery(['getCurrentUser', userId], () => qualioApi.getUserById(userId as number));

  const companyRecord = user?.companies?.find((comp: IncomingCompany) => comp.id === companyId);

  const isSupplierCapabilityEnabled = capabilities?.suppliers ?? false;

  return {
    user,
    isUserLoading,
    userError,
    userGroups: companyRecord?.usergroups,
    permissions: companyRecord?.permissions,
    isValidSuppliersPlan: isSupplierCapabilityEnabled,
  };
};
