import React, { useCallback, useState } from 'react';

import {
  QText,
  QBox,
  QButton,
  QButtonGroup,
  QHeading,
  QModal,
  QTabs,
  QSpinner,
  QTag,
  QModalHeader,
  QModalBody,
  QModalActions,
  QCloseButton,
  QMenuButton,
  QMenuItem,
  QStack,
  QIconButton,
  QTooltip,
  QriAttributes,
  useReferenceDrawer,
} from '@qualio/ui-components';
import {
  useSupplierArchiveMutation,
  useSupplierQuery,
  useSupplierUnArchiveMutation,
  useSupplierExportMutation,
  useRevertSupplierToDraftMutationType,
} from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { STATUS_CELL_MAPPING } from 'lib';
import { Outlet, useLocation, useOutletContext, useParams, useNavigate } from 'react-router-dom';
import { QualioUser, Supplier, SupplierStatus } from 'types';
import { routes } from 'utils';
import { ChangeControlAlerts, StatusChangeModal } from 'v2components';

import {
  ALLOWED_STATUSES_FOR_ARCHIVE,
  ALLOWED_STATUSES_FOR_EDIT,
  ALLOWED_STATUSES_FOR_REVERT_TO_DRAFT,
  ALLOWED_STATUSES_FOR_UNARCHIVE,
  TABS,
} from './SupplierDetailsView.const';

export const SupplierDetailsView: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { supplierId } = useParams<{ supplierId: string }>();
  const { supplier, refetchSupplier, isSupplierLoading } = useSupplierQuery(supplierId!);
  const [modal, setModal] = useState<any | null>(null);
  const [qriAttributes, setQriAttributes] = useState<QriAttributes | undefined>(undefined);

  const { suppliersDetailsRelatedRecordsDrawer } = useFlags();

  const activeRouteId = (): string => {
    return TABS.find((tab) => pathname.includes(tab.id))?.id || '';
  };

  const handleClick = useCallback(() => {
    if (supplier?.company && supplier.supplier) {
      const qriAttributes = {
        companyId: supplier.company,
        domain: 'suppliers',
        domainType: 'supplier',
        id: supplier.supplier,
      };

      setQriAttributes(qriAttributes);
    }
  }, [supplier]);

  useReferenceDrawer(
    {
      qri: qriAttributes,
      isLoading: false,
      label: supplier?.name ?? '',
    },
    () => {
      setQriAttributes(undefined);
    },
  );

  const onModalSuccess = () => {
    setModal(null);
    refetchSupplier();
  };

  const [isStatusChangeModalVisible, setIsChangeStatusModalVisible] = useState<string | undefined>(undefined);

  const { isArchiveSupplierLoading, archiveSupplierMutate } = useSupplierArchiveMutation(onModalSuccess);

  const { isExportSupplierLoading, exportSupplierMutate } = useSupplierExportMutation(onModalSuccess);

  const { isUnArchiveSupplierLoading, unArchiveSupplierMutate } = useSupplierUnArchiveMutation(onModalSuccess);
  const { isRevertSupplierToDraftLoading, revertSupplierToDraftMutate } =
    useRevertSupplierToDraftMutationType(onModalSuccess);

  type ModalTypes = 'archive' | 'revertToDraft' | 'unarchive' | 'export';
  const modalVariations: Record<ModalTypes, any> = {
    archive: {
      title: 'Archive',
      description: (
        <>
          <QText>Are you sure you want to archive this supplier?</QText>
          <QText>You can unarchive it at any time.</QText>
        </>
      ),
      buttonProps: {
        onClick: () => supplier && archiveSupplierMutate(supplier),
        variant: 'solid',
        isDestructive: true,
        children: 'Archive',
        isLoading: isArchiveSupplierLoading,
        'data-cy': 'archive-modal-confirm',
      },
    },
    unarchive: {
      title: 'Unarchive',
      description: (
        <>
          <QText>Are you sure you want to unarchive this supplier? </QText>
          <QText>The status will change to "Draft".</QText>
        </>
      ),
      buttonProps: {
        onClick: () => supplier && unArchiveSupplierMutate(supplier),
        variant: 'solid',
        children: 'Unarchive',
        isLoading: isUnArchiveSupplierLoading,
        'data-cy': 'unarchive-modal-confirm',
      },
    },
    revertToDraft: {
      title: 'Revert to Draft',
      description: <QText>Do you want to revert this supplier to the "Draft" state?</QText>,
      buttonProps: {
        onClick: () => supplier && revertSupplierToDraftMutate(supplier),
        variant: 'solid',
        children: 'Revert',
        isLoading: isRevertSupplierToDraftLoading,
        'data-cy': 'revert-to-draft-modal-confirm',
      },
    },

    export: {
      title: 'Export Supplier',
      description: (
        <QStack>
          <QText>Supplier will be exported as a zip containing :</QText>
          <QText>
            <li>A PDF file with all the supplier details</li>
            <li>Supporting documents</li>
            <li>Audit files</li>
          </QText>
        </QStack>
      ),
      buttonProps: {
        onClick: () => supplier && exportSupplierMutate(supplier),
        isLoading: isExportSupplierLoading,
        variant: 'solid',
        children: 'Export Supplier',
        'data-cy': 'export-supplier-modal-confirm',
      },
    },
  };

  const showApproverBadge = (status = 'DRAFT') => {
    const tag = STATUS_CELL_MAPPING[status];
    return <QTag variantColor={tag.tagColor}>{tag.text}</QTag>;
  };

  const combinedLoadingState =
    isArchiveSupplierLoading || isUnArchiveSupplierLoading || isRevertSupplierToDraftLoading || isExportSupplierLoading;

  const showStatusChangeModal = (status: string) => {
    setIsChangeStatusModalVisible(status);
  };

  const hideStatusChangeModal = () => {
    setIsChangeStatusModalVisible(undefined);
  };

  return (
    <QBox display="flex" flexDirection="column">
      <QButton leftIcon={'ArrowLeft'} onClick={() => navigate(`/${routes.supplierList}`)} variant="link" size="sm">
        Back
      </QButton>
      <QBox display="flex" flexDirection="row" justifyContent="space-between" mt={4} mb={4}>
        {!isSupplierLoading ? (
          <span>
            <QHeading as="h1" size="lg">
              {supplier?.name}
            </QHeading>
            {showApproverBadge(supplier?.status)}
          </span>
        ) : null}
        {supplier?.status ? (
          <QButtonGroup>
            <QMenuButton data-cy="details-menu" buttonLabel="menu" variant="icon">
              <QMenuItem data-cy="supplier-export-button" onClick={() => setModal(modalVariations.export)}>
                Export
              </QMenuItem>
              {ALLOWED_STATUSES_FOR_ARCHIVE.includes(supplier?.status) ? (
                <QMenuItem data-cy="supplier-archive-button" onClick={() => setModal(modalVariations.archive)}>
                  Archive
                </QMenuItem>
              ) : null}
              {ALLOWED_STATUSES_FOR_UNARCHIVE.includes(supplier?.status) ? (
                <QMenuItem onClick={() => setModal(modalVariations.unarchive)} data-cy="supplier-unarchive-button">
                  Unarchive
                </QMenuItem>
              ) : null}
            </QMenuButton>
            {suppliersDetailsRelatedRecordsDrawer && (
              <QTooltip label="View related records" placement="top">
                <QBox>
                  <QIconButton
                    variant="outline"
                    onClick={handleClick}
                    iconName="Related"
                    aria-label="View related records"
                    data-cy="related-records-button"
                  />
                </QBox>
              </QTooltip>
            )}

            {supplier && ALLOWED_STATUSES_FOR_EDIT.includes(supplier?.status) ? (
              <QButton onClick={() => navigate(`/${routes.editSupplier}/${supplierId}`)} data-cy="supplier-edit-button">
                Edit
              </QButton>
            ) : null}
            {ALLOWED_STATUSES_FOR_REVERT_TO_DRAFT.includes(supplier?.status) ? (
              <QButton
                onClick={() => setModal(modalVariations.revertToDraft)}
                data-cy="supplier-revert-to-draft-button"
              >
                Revert to draft
              </QButton>
            ) : null}
          </QButtonGroup>
        ) : null}
      </QBox>
      <QBox mb={4}>
        <ChangeControlAlerts
          changeControl={supplier?.changeControl}
          supplierStatus={supplier?.status as SupplierStatus}
          handleStatusChangeModal={showStatusChangeModal}
          sponsor={supplier?.sponsor as QualioUser}
        />
      </QBox>
      <QTabs
        data-cy="supplier-details-tabs"
        activeTabId={activeRouteId()}
        onClick={(tabItem) => navigate(`/${routes.supplierDetails.root}/${supplier?.supplier}/${tabItem.id}`)}
        tabs={TABS}
      />
      {isSupplierLoading ? (
        <QBox w="100%" textAlign="center" p={5}>
          <QSpinner />
        </QBox>
      ) : (
        <QBox my={6}>
          <Outlet context={{ supplier, refetchSupplier, isSupplierLoading }} />
        </QBox>
      )}

      {modal ? (
        <QModal isOpen={!!modal} onClose={() => setModal(null)}>
          <QModalHeader>
            <QText>{modal.title}</QText>
            <QCloseButton onClick={() => setModal(null)} />
          </QModalHeader>
          <QModalBody>{modal.description}</QModalBody>
          <QModalActions>
            <QButton onClick={() => setModal(null)} variant="outline">
              Cancel
            </QButton>
            <QButton {...modal.buttonProps} data-cy={modal.buttonProps['data-cy']} isLoading={combinedLoadingState} />
          </QModalActions>
        </QModal>
      ) : null}

      <StatusChangeModal
        isVisible={!!isStatusChangeModalVisible}
        onClose={hideStatusChangeModal}
        onSuccess={() => {
          setIsChangeStatusModalVisible(undefined);
          refetchSupplier();
        }}
        current={supplier}
        status={isStatusChangeModalVisible}
      />
    </QBox>
  );
};

type OutletSupplierType = {
  supplier: Supplier;
  isLoading: boolean;
  refetchSupplier: () => void;
};

export const useRouterOutletSupplier = (): OutletSupplierType => {
  return useOutletContext<OutletSupplierType>();
};
